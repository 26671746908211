import Vue from 'vue';
import Vuex from 'vuex';
import Airtable from 'airtable';
import env from '../../../server/env';
import axios from 'axios';
import router from '../router';

Vue.use(Vuex);
// @ts-ignore
export default new Vuex.Store({
	state: {
		isLoading: true,
		organizer: {
			id: 0,
			name: null,
			street: null,
			streetnumber: null,
			zip: null,
			location: null,
			country: null,
			state: null,
			url: null,
			contactPerson: null,
			email: null,
			telephone: null,
			events: null,
		},
		events: [],
		eventDetail: {
			id: 0,
			name: null,
			url: null,
			type: null,
			accessRequirements: null,
			topics: null,
			setup: null,
			others: null,
			duration: 0,
			durationType: null,
			targetGroups: [],
			costs: 0,
			currency: null,
			reduction: null,
			additionalCosts: null,
			states: [],
			contactPerson: null,
			telephone: null,
			email: null,
			keywords: [],
			courseDatesRaw: [],
			courseDates: [],
			keywordList: [],
		},
	},
	mutations: {
		SET_ORGANIZER(state, organizer) {
			state.organizer = {
				id: organizer.getId(),
				name: organizer.get('Name'),
				street: organizer.get('Straße'),
				streetnumber: organizer.get('Hausnummer'),
				zip: organizer.get('PLZ'),
				location: organizer.get('Ort'),
				country: organizer.get('Land'),
				state: organizer.get('Bundesland'),
				url: organizer.get('URL'),
				contactPerson: organizer.get('Ansprechpartner'),
				email: organizer.get('E-Mail'),
				telephone: organizer.get('Telefon'),
				events: organizer.get('Veranstaltungen'),
			};
		},
		SET_EVENTS(state, data) {
			state.events = data;
		},
		SET_EVENT_DETAIL(state, data) {
			state.eventDetail = {
				id: data.event.getId(),
				name: data.event.get('Name'),
				url: data.event.get('Event URL'),
				type: data.event.get('Veranstaltungsart'),
				accessRequirements: data.event.get('Zugangsvoraussetzung'),
				topics: data.event.get('Themen'),
				setup: data.event.get('Aufbau'),
				others: data.event.get('Sonstiges'),
				duration: data.event.get('Dauer'),
				durationType: data.event.get('Art der Dauer'),
				targetGroups: data.event.get('Zielgruppen') ?? [],
				costs: data.event.get('Kosten'),
				currency: data.event.get('Währung'),
				reduction: data.event.get('Reduzierung'),
				additionalCosts: data.event.get('Zusätzliche Kosten'),
				states: data.event.get('Bundesland') ?? [],
				contactPerson: data.event.get('Ansprechpartner'),
				telephone: data.event.get('Telefon'),
				email: data.event.get('E-Mail'),
				keywords: data.event.get('Keywords') ?? [],
				courseDatesRaw: data.event.get('Kursbeginn') ?? [],
				courseDates: data.courses,
				keywordList: data.keywordList,
			};
		},
		SET_EVENT_KEYWORDS(state, data) {
			// @ts-ignore
			state.eventDetail = {
				keywordList: data,
			};
		},
		UPDATE_ORGANIZER(state, data) {
			state.organizer = data;
		},
		UPDATE_EVENT_DETAIL(state, data) {
			state.eventDetail = data;
		},
		SET_LOADING(state, data) {
			state.isLoading = data.isLoading;
		},
	},
	actions: {
		async loadOrganizer({ commit }, id) {
			const base = new Airtable({ apiKey: env.apiKey }).base(env.base);

			const organizer = await base(env.tableOrganizer).find(id);
			const events = organizer.get('Veranstaltungen');

			const eventsTransformend = [];

			if (events) {
				for (const eventId of events) {
					const event = await base(env.tableEvents).find(eventId);

					eventsTransformend.push({ id: event.getId(), name: event.get('Name'), type: event.get('Veranstaltungsart') });
				}
			}

			commit('SET_ORGANIZER', organizer);
			commit('SET_EVENTS', eventsTransformend);
			commit('SET_LOADING', { isLoading: false });
		},
		async loadEvent({ commit }, id) {
			commit('SET_LOADING', { isLoading: true });

			const base = new Airtable({ apiKey: env.apiKey }).base(env.base);

			const event = await base(env.tableEvents).find(id);
			const courses = event.get('Kursbeginn');

			const coursesTransformend = [];

			if (courses) {
				for (const courseId of courses) {
					const eventEntry = await base(env.tableCourses).find(courseId);

					coursesTransformend.push({ id: eventEntry.getId(), date: eventEntry.get('Datum'), location: eventEntry.get('Ort') });
				}
			}

			// @ts-ignore
			const request = await axios.get('https://dewist-events-server.netlify.app/.netlify/functions/keywords', { crossdomain: true });
			const keywords = request.data;

			commit('SET_EVENT_DETAIL', {
				event,
				courses: coursesTransformend,
				// @ts-ignore
				keywordList: keywords.filter((keyword) => keyword !== null),
			});
			commit('SET_LOADING', { isLoading: false });
		},
		async updateOrganizer({ commit }, organizer) {
			commit('UPDATE_ORGANIZER', organizer);
		},
		async saveOrganizer({ commit, state }) {
			commit('SET_LOADING', { isLoading: true });

			const base = new Airtable({ apiKey: env.apiKey }).base(env.base);

			// @ts-ignore
			const organizer = await base(env.tableOrganizer).update(state.organizer.id, {
				'Name': state.organizer.name,
				'Ort': state.organizer.location,
				'PLZ': state.organizer.zip,
				'URL': state.organizer.url,
				'Land': state.organizer.country,
				'Bundesland': state.organizer.state,
				'Telefon': state.organizer.telephone,
				'E-Mail': state.organizer.email,
				'Straße': state.organizer.street,
				'Hausnummer': state.organizer.streetnumber,
				'Ansprechpartner': state.organizer.contactPerson,
			});
			commit('SET_LOADING', { isLoading: false });
		},
		async deleteEvent({ commit, state }, id) {
			commit('SET_LOADING', { isLoading: true });

			const base = new Airtable({ apiKey: env.apiKey }).base(env.base);

			const event = await base(env.tableEvents).destroy(id);

			router.go(0);
		},
		async updateEventDetail({ commit }, eventDetail) {
			commit('UPDATE_EVENT_DETAIL', eventDetail);
		},
		async saveEventDetail({ commit, state }, doUpdate) {
			commit('SET_LOADING', { isLoading: true });

			const base = new Airtable({ apiKey: env.apiKey }).base(env.base);

			const data = {
				'Name': state.eventDetail.name,
				'Themen': state.eventDetail.topics,
				'Veranstaltungsart': state.eventDetail.type,
				// @ts-ignore
				'Dauer': parseInt(state.eventDetail.duration, 10),
				'Art der Dauer': state.eventDetail.durationType,
				'Aufbau': state.eventDetail.setup,
				'Sonstiges': state.eventDetail.others,
				'Zugangsvoraussetzung': state.eventDetail.accessRequirements,
				// @ts-ignore
				'Kosten': parseInt(state.eventDetail.costs, 10),
				'Währung': state.eventDetail.currency,
				'Zusätzliche Kosten': state.eventDetail.additionalCosts,
				'Reduzierung': state.eventDetail.reduction,
				'Event URL': state.eventDetail.url,
				'Zielgruppen': state.eventDetail.targetGroups,
				'Bundesland': state.eventDetail.states,
				'Keywords': state.eventDetail.keywords,
				'Ansprechpartner': state.eventDetail.contactPerson,
				'Telefon': state.eventDetail.telephone,
				'E-Mail': state.eventDetail.email,
			};

			if (doUpdate) {
				// @ts-ignore
				const event = await base(env.tableEvents).update(state.eventDetail.id, data);
			} else {
				const event = await base(env.tableEvents).create(data);

				// update organizer with new event
				// @ts-ignore
				const organizer = await base(env.tableOrganizer).update(state.organizer.id, {
					// @ts-ignore
					Veranstaltungen: [...state.organizer.events, event.getId()],
				});
			}
			router.push({ path: `/organizer/${state.organizer.id}/` });
		},
		async saveCourse({ commit, state }, id) {
			commit('SET_LOADING', { isLoading: true });

			const base = new Airtable({ apiKey: env.apiKey }).base(env.base);

			// @ts-ignore
			const course = state.eventDetail.courseDates.find((course) => course.id === id);

			// @ts-ignore
			const courseResponse = await base(env.tableCourses).create({ Datum: course.date, Ort: course.location });

			// @ts-ignore
			const event = await base(env.tableEvents).update(state.eventDetail.id, {
				Kursbeginn: [...state.eventDetail.courseDatesRaw, courseResponse.getId()],
			});

			router.go(0);
		},
		async updateCourse({ commit, state }, id) {
			commit('SET_LOADING', { isLoading: true });

			const base = new Airtable({ apiKey: env.apiKey }).base(env.base);

			// @ts-ignore
			const course = state.eventDetail.courseDates.find((course) => course.id === id);

			// @ts-ignore
			const courseResponse = await base(env.tableCourses).update(course.id, { Datum: course.date, Ort: course.location });

			router.go(0);
		},
		async deleteCourse({ commit, state }, id) {
			commit('SET_LOADING', { isLoading: true });

			const base = new Airtable({ apiKey: env.apiKey }).base(env.base);

			// @ts-ignore
			const course = state.eventDetail.courseDates.find((course) => course.id === id);

			if (course) {
				// @ts-ignore
				const courseResponse = await base(env.tableCourses).destroy(course.id);
			}

			router.go(0);
		},
	},
	modules: {},
});

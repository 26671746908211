














import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
	name: 'Header',
	components: {},
	computed: {
		...mapState(['isLoading']),
	},
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const airtableApiKey = 'patxNyjKhkbRp0ZWl.a2c6b4f6e7b2bbcb20199fb5ff64da4c6c19736ff41b41c1ce51d44239d0ec2f';
const baseProd = 'apphJ2HWrHKnzwTcu';
const baseTest = 'appsTwN9E2zqTNwic';
const tableOrganizer = 'Anbieter';
const tableEvents = 'Veranstaltungen';
const tableCourses = 'Kursbeginn';
const newsletter2GoAPIKey = 'f8ntkq9f_N9ismo6_eBG6aE1e_NB8HUTMx_47FRnFvG:d9m6mw8z';
const newsletter2GOAuthToken = '__eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfXy5haWQiOjI2NzIzMCwiX18uZW52aXJvbm1lbnQiOiJwcm9kdWN0aW9uIiwiX18uZXhwaXJlc0F0IjoxNjA3MDE2NDQyLCJfXy5zY29wZSI6IiIsIl9fLmNpZCI6IjM3NDA3X045aXNtbzZfZUJHNmFFMWVfTkI4SFVUTXhfNDdGUm5GdkciLCJfXy5yZDEiOjM1NDgxLCJfXy5yZDIiOjMyNTUzfQ.SA3-ULCAqoG1Jm5vyDfxzK9UYtggX1z3g98rb7N-10w';
const newsletter2GoListId = 'ke5t6i93';
const states = [
    { id: 'DE-BW', name: 'Baden-Württemberg' },
    { id: 'DE-BY', name: 'Bayern' },
    { id: 'DE-BE', name: 'Berlin' },
    { id: 'DE-BB', name: 'Brandenburg' },
    { id: 'DE-HB', name: 'Bremen' },
    { id: 'DE-HH', name: 'Hamburg' },
    { id: 'DE-HE', name: 'Hessen' },
    { id: 'DE-MV', name: 'Mecklenburg-Vorpommern' },
    { id: 'DE-NI', name: 'Niedersachsen' },
    { id: 'DE-NW', name: 'Nordrhein-Westfalen' },
    { id: 'DE-RP', name: 'Rheinland-Pfalz' },
    { id: 'DE-SL', name: 'Saarland' },
    { id: 'DE-SN', name: 'Sachsen' },
    { id: 'DE-ST', name: 'Sachsen-Anhalt' },
    { id: 'DE-SH', name: 'Schleswig-Holstein' },
    { id: 'DE-TH', name: 'Thüringen' },
    { id: 'AT-1', name: 'Burgenland' },
    { id: 'AT-2', name: 'Kärnten' },
    { id: 'AT-3', name: 'Niederösterreich' },
    { id: 'AT-4', name: 'Oberösterreich' },
    { id: 'AT-5', name: 'Salzburg' },
    { id: 'AT-6', name: 'Steiermark' },
    { id: 'AT-7', name: 'Tirol' },
    { id: 'AT-8', name: 'Vorarlberg' },
    { id: 'AT-9', name: 'Wien' },
    { id: 'CH-AG', name: 'Aargau' },
    { id: 'CH-AR', name: 'Appenzell Ausserrhoden' },
    { id: 'CH-AI', name: 'Appenzell Innerrhoden' },
    { id: 'CH-BL', name: 'Basel-Landschaft' },
    { id: 'CH-BS', name: 'Basel-Stadt' },
    { id: 'CH-BE', name: 'Bern' },
    { id: 'CH-FR', name: 'Freiburg' },
    { id: 'CH-GE', name: 'Genf' },
    { id: 'CH-GL', name: 'Glarus' },
    { id: 'CH-GR', name: 'Graubünden' },
    { id: 'CH-JU', name: 'Jura' },
    { id: 'CH-LU', name: 'Luzern' },
    { id: 'CH-NE', name: 'Neuenburg' },
    { id: 'CH-NW', name: 'Nidwalden' },
    { id: 'CH-OW', name: 'Obwalden' },
    { id: 'CH-SH', name: 'Schaffhausen' },
    { id: 'CH-SZ', name: 'Schwyz' },
    { id: 'CH-SO', name: 'Solothurn' },
    { id: 'CH-SG', name: 'St. Gallen' },
    { id: 'CH-TI', name: 'Tessin' },
    { id: 'CH-TG', name: 'Thurgau' },
    { id: 'CH-UR', name: 'Uri' },
    { id: 'CH-VD', name: 'Waadt' },
    { id: 'CH-VS', name: 'Wallis' },
    { id: 'CH-ZG', name: 'Zug' },
    { id: 'CH-ZH', name: 'Zürich' },
];
const targetGroups = [
    { id: 1, name: 'Pädagogen' },
    { id: 2, name: 'Biologen' },
    { id: 3, name: 'Ehrenamtliche' },
    { id: 4, name: 'Erzieher' },
    { id: 5, name: 'Forstleute' },
    { id: 6, name: 'Interessierte' },
    { id: 7, name: 'Multiplikatoren' },
    { id: 8, name: 'Studierende' },
    { id: 9, name: 'Umweltbildner' },
];
exports.default = {
    apiKey: airtableApiKey,
    base: baseProd,
    tableOrganizer,
    tableEvents,
    newsletter2GoAPIKey,
    newsletter2GoListId,
    newsletter2GOAuthToken,
    states,
    targetGroups,
    tableCourses
};

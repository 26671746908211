<template>
	<div class="home">
		<EventOverview />
	</div>
</template>

<script>
// @ is an alias to /src
import EventOverview from '@/components/EventOverview.vue';

export default {
	name: 'overview',
	components: {
		EventOverview,
	},
};
</script>





























































































import Vue from 'vue';
import { mapState } from 'vuex';

import store from '../store';
import Header from '@/components/Header.vue';

export default Vue.extend({
	name: 'HelloWorld',
	components: {
		Header,
	},
	data: () => ({
		dialog: false,
		dialogDelete: false,
		headers: [
			{
				text: 'Name',
				align: 'start',
				sortable: false,
				value: 'name',
			},
			{ text: 'Typ', value: 'type' },
			{ text: 'Aktion', value: 'actions', sortable: false },
		],
		editedIndex: -1,
		editedItem: {
			name: '',
			type: 0,
		},
		defaultItem: {
			name: '',
			type: 0,
		},
	}),

	computed: {
		errorMessage() {
			const text = '';

			const errors = [];

			if (this.organizer.name === '') {
				errors.push('Name');
			}

			if (this.organizer.location === '') {
				errors.push('Ort');
			}

			if (this.organizer.street === '') {
				errors.push('Straße');
			}

			if (this.organizer.streetnumber === '') {
				errors.push('Hausnummer');
			}

			if (this.organizer.email === '') {
				errors.push('E-Mail');
			}

			if (errors.length === 0) {
				return null;
			} else {
				return 'Bitte bearbeiten Sie noch folgende Felder: ' + errors.join(', ') + '<br>Danach können sie speichern.';
			}
		},
		...mapState(['isLoading', 'events', 'organizer']),
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
		organizer: {
			handler: function(newVal, oldVal) {
				this.$store.dispatch('updateOrganizer', newVal);
			},
			deep: true,
		},
	},
	mounted() {
		this.$store.dispatch('loadOrganizer', this.$route.params.id);
	},
	methods: {
		// @ts-ignore
		editItem(item) {
			// @ts-ignore
			this.$router.push({ path: `/organizer/${this.$route.params.id}/event/${item.id}` });
		},
		// @ts-ignore
		addNewEvent(item) {
			this.$router.push({ path: `/organizer/${this.$route.params.id}/event/new` });
		},
		// @ts-ignore
		deleteItem(item) {
			this.editedIndex = this.events.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
			// @ts-ignore
			this.$store.dispatch('deleteEvent', this.editedItem.id);
			// this.events.splice(this.editedIndex, 1);
			this.closeDelete();
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},
		saveOrganizer() {
			this.$store.dispatch('saveOrganizer');
		},
	},
});
